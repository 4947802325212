import * as React from "react"
import SiteContainer from "../site-container";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const WorkflowsPage = ({pageContext: {recipes}}) => {
    const features = [
        "salesforce-facebook-ads-offline-conversions"
    ]

    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty Workflows - Attribution and Audience Creation Made Easy</title>
                <meta name="description" content="Workflows guide you through configuring multiple Firstparty components to help achieve
                    an objective, and can help you solve marketing problems like analytics,
                    attribution, and audience creation in just a few clicks." />
                
            </Helmet>

            <section className="py-8 py-md-11">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={10} className="text-center">
                            <h1 className="display-1 fw-bold aos-init aos-animate" data-aos="fade-up"
                                data-aos-delay="50">Attribution and Audience Creation Made Easy
                            </h1>
                            <p className="lead text-muted mb-6 aos-init aos-animate" data-aos="fade-up"
                                data-aos-delay="100">
                                Workflows guide you through configuring multiple Firstparty components to help achieve
                                an objective, and can help you solve marketing problems like analytics,
                                attribution, and audience creation in just a few clicks.
                            </p>
                            <p className="mb-7 mb-md-9 aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                                <a className="btn btn-primary shadow lift" href="/signup/">
                                    Get Started for Free<i className="fe fe-arrow-right ms-2" />
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="pb-8 pb-md-11">
                <Container>
                    <Row>
                        {recipes.map(function(recipe) {
                            if(features.includes(recipe.slug) ) {
                                return <Col xs={12} md={8} key={recipe.id} className="mb-6">
                                    <div className="card bg-cover overlay overlay-primary overlay-80 text-white shadow-light-lg mb-6 h-100">
                                    <span className="badge rounded-pill bg-light badge-float badge-float-inside">
                                        <span className="h6 text-uppercase">Featured</span>
                                    </span>
                                        <a className="card-body my-auto" href={`/workflows/${recipe.slug}/`}>
                                            <h3 className="mt-auto">
                                                {recipe.name}
                                            </h3>
                                            <p className="mb-0 text-white-80">
                                                {recipe.description}
                                            </p>
                                        </a>
                                        <a className="card-meta" href={`/workflows/${recipe.slug}/`}>
                                            <hr className="card-meta-divider" />
                                            <h6 className="text-uppercase text-white me-2 mb-0">
                                                {recipe.total_steps} Steps
                                            </h6>
                                        </a>
                                    </div>
                                </Col>
                            }

                            return <Col xs={12} md={6} lg={4} key={recipe.id} className="mb-6">
                                <div className="card mb-6 mb-lg-0 h-100 shadow-light-lg">
                                    <a className="card-body my-auto" href={`/workflows/${recipe.slug}/`}>
                                        <h3 className="text-black pt-7">
                                            {recipe.name}
                                        </h3>
                                        <p className="mb-0 text-muted">
                                            {recipe.description}
                                        </p>
                                    </a>
                                    <a className="card-meta" href={`/workflows/${recipe.slug}/`}>
                                        <hr className="card-meta-divider" />
                                        <h6 className="text-uppercase me-2 mb-0">
                                            {recipe.total_steps} Steps
                                        </h6>
                                    </a>
                                </div>
                            </Col>
                        })}
                    </Row>
                </Container>
            </section>

        </SiteContainer>
    )
}

export default WorkflowsPage
